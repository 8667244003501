<template>
  <VCard class="ma-7" flat>
    <div class="d-flex pb-5">
      <span class="page_title">Заведения</span>
      <span class="ml-2 font-weight-bold text-h6 min-width-70">
        ({{ restaurants.length ? restaurants[0].totalCount : 0 }})
      </span>
      <RouterLink :to="{ name: 'add_restaurant' }">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Добавить заведение
        </VBtn>
      </RouterLink>
    </div>
    <div class="page-search mt-4 mb-6">
      <div class="search_category d-flex pb-4">
        <span class="search__title pr-4 align-center">Поиск</span>
        <div class="search_restaurant__title pb-2">
          <form @submit.prevent="filterRestaurants">
            <VTextField
              v-model="restaurantsFilter.restaurantTitle"
              append-icon="mdi-magnify"
              autofocus
              background-color="#F2F2F2"
              color="black"
              dense
              outlined
              placeholder="Введите название заведения..."
            />
          </form>
        </div>
      </div>
    </div>
    <RestaurantsTable
      :filter-title="restaurantsFilter.restaurantTitle"
      :restaurants="restaurants"
      @filterChanged="filterRestaurants"
    />
    <div class="paginationButtons">
      <VBtn
        class="ma-2"
        color="success"
        :disabled="!restaurantListHasPrevPage()"
        tile
        @click="fetchPrevRestaurantPage"
      >
        <VIcon left>
          mdi-chevron-left
        </VIcon>
        Предыдущие
      </VBtn>
      <VBtn
        class="ma-2"
        color="success"
        :disabled="!restaurantListHasNextPage()"
        tile
        @click="fetchNextRestaurantPage"
      >
        Следующие
        <VIcon right>
          mdi-chevron-right
        </VIcon>
      </VBtn>
    </div>
  </VCard>
</template>

<script>
import RestaurantsTable from '@/components/RestaurantsTable.vue';
import { fetchRestaurants } from '@/api/api';

export default {
  components: {
    RestaurantsTable,
  },
  created() {
    this.fetchListOfRestaurants();
  },
  data() {
    return {
      restaurants: [],
      restaurantsFilter: {
        cityTitle: '',
        restaurantTitle: '',
        deliveryService: '',
        managerEmail: '',
      },
      currentPageOffset: 0,
    };
  },
  methods: {
    restaurantListHasNextPage() {
      return this.restaurants.length === 20;
    },
    restaurantListHasPrevPage() {
      return this.currentPageOffset !== 0;
    },
    fetchNextRestaurantPage() {
      this.currentPageOffset += 20;
      this.fetchListOfRestaurants();
    },
    fetchPrevRestaurantPage() {
      this.currentPageOffset -= 20;
      this.fetchListOfRestaurants();
    },
    filterRestaurants(restaurantFilter) {
      this.restaurantsFilter = restaurantFilter;
      this.currentPageOffset = 0;
      this.fetchListOfRestaurants();
    },
    fetchListOfRestaurants() {
      fetchRestaurants(
        this.restaurantsFilter.cityTitle,
        this.restaurantsFilter.restaurantTitle,
        this.restaurantsFilter.deliveryService,
        this.restaurantsFilter.restaurantState,
        this.restaurantsFilter.managerEmail,
        this.restaurantsFilter.hasHubs,
        this.currentPageOffset,
      ).then((response) => {
        this.restaurants = response || [];
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/assets/scss/page.scss';

.search-info {
  margin-top: 10px;
  float: left;
  font-weight: bold;
}

.search {
  left: 44px;
  width: 410px;
}

a {
  text-decoration: none;
}

.page-search {
  margin: auto;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}

.white--text {
  border-radius: 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-left: 44px;
}

.pa-4 {
  margin-left: 40px;
  font-style: normal;
}

.search_restaurant__title{
  font-size: 12px;
  width: 40%;
  height: 15px;
  color: rgb(0, 0, 0, 0.5);
}

.search__title {
  font-family: 'AvenirNextCyr Black';
  font-size: 13pt;
}

.paginationButtons {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.min-width-70 {
  min-width: 70px;
}
</style>
